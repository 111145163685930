import { LOADING, ADD, DELETE, LIST, DETAILS, ALL } from '../../types/collections'
// **  Initial State
const initialState = {
  data: [],
  start: 0,
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  percentage: 0,
  params: {},
  loading: true,
  all: [],
}
const findCollectionAndUpdate = (data, item) => {
  var foundIndex = data.findIndex((x) => x._id == item._id);
  const oldItem = data[foundIndex];
  data[foundIndex] = { ...oldItem, ...item };
  return [...data];
};


const collectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST:
      return {
        ...state,
        data: action.payload.results,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        params: { limit: action.payload.limit, page: action.payload.page },
        loading: false,
      }
    case ALL:
      return {
        ...state,
        all: action.payload.results,
      }
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case "CHANGE_COLLECTION_STATUS":
      return {
        ...state,
        data: findCollectionAndUpdate(state.data, action.payload),
      };
    default:
      return state
  }
}

export default collectionsReducer
