const initialFilter ={
    limit:process.env.REACT_APP_PAGE_LIMIT,
    page: 1,
    search: {
        type: "",
        value: ""
    },
    sorted: {
        column: "created_at",
        order: "desc"
    },
}

const initialState={
    order:{},
    loading:true,
    filter:initialFilter
}


const batchProcessReducer=(state=initialState,action)=>{
    switch (action.type){
        case "GET_BATCH_ORDER" :
        return{
            ...state,
            order:action.payload.data.results,
            loading:false,
            page:action.payload.data?.page,
            totalPage:action.payload.data?.totalPages,
            totalResult:action.payload.data?.totalResults,
            filter: initialFilter
        }
        case "SET_FILTER":
            return {
                ...state,
                loading: true,
                filter: {
                    ...state.filter,
                    ...action.payload
                }
            }
        case "RESET_FILTER":{
            return{
                ...state,
                loading: true,
                filter: {
                    ...initialFilter
                }
            }
        }
        default :return state
    }
}

export default batchProcessReducer