// ** Initial State
const initialState = {
  merchantLocation: "",
  locations:[]
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_MERCHANT_LOCATION":
      const {
        location_id,
        location_name,
        isPreventLocation = true,
      } = action?.payload;
      let location_id_storage = null;
      let location_name_storage = null;

      if (isPreventLocation && location_id) {
        console.log("if action?.payload", action?.payload);
        localStorage.setItem(
          "merchant_location",
          JSON.stringify({
            location_id: location_id,
            location_name: location_name,
          })
        );
      } else if (
        localStorage.getItem("merchant_location") &&
        JSON.parse(localStorage.getItem("merchant_location"))
      ) {
        console.log("else action?.payload", action?.payload);
        const { location_id, location_name } = JSON.parse(
          localStorage.getItem("merchant_location")
        );
        location_name_storage = location_name;
        location_id_storage = location_id;
      }
      return {
        ...state,
        merchantLocation: location_id_storage || location_id,
        location_name: location_name_storage || location_name,
      };
      
      case "LOCATIONS":
        return {
          ...state,
          locations: action?.payload
        };
      
      default:
      return state;
  }
};

export default locationReducer;
