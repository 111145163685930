import { compareTwoArrayOfObjects } from "../../../utility/Utils";
import { LOADING, LOGIN, LOGOUT } from "../../types/auth";
// **  Initial State
const initialState = {
  userData: {},
  tokenData: {},
  isAuthenticated: false,
  isLoading: true,
  is_bank_account_added: false,
  is_online_store_setting_pending: false,
};
//ddf
const authReducer = (state = initialState, action) => {
  console.log("initialState", initialState?.is_online_store_setting_pending);
  switch (action.type) {
    case LOGIN:
      const expiryEndDate =
        action.payload?.userData?.merchant_active_plan[0]?.end_date;
      const newDateWithExpiry = new Date(expiryEndDate);
      const expiryDate = expiryEndDate
        ? newDateWithExpiry.getTime() + 86400000
        : null;
      const todayDate = new Date().getTime();
      // const isSubscriptionFree =
      //   action.payload?.userData?.merchant_active_plan[0]?.subscription_plan_info?.type?.toUpperCase() ===
      //   "FREE";
      console.log("isTrialEnded", todayDate > expiryDate, action.payload);
      return {
        ...state,
        userData: {
          ...action.payload.userData,
          currency: {
            ...action.payload.userData.merchant_location_info[0].country,
            symbol:
              action.payload.userData.merchant_location_info[0].country
                ?.currency_symbol,
          },
        },
        tokenData: action.payload.tokens,
        isAuthenticated: true,
        isLoading: false,
        isTrialEnded: todayDate > expiryDate,
        is_bank_account_added: action.payload.userData.is_bank_account_added,
        is_online_store_setting_pending:
          action.payload.userData.is_online_store_setting_pending,
      };
    case "ONLINE_SETTING_PANDING_UPDATE":
      const allLocationSettingPending = action.payload.some(
        (item) =>
          item?.merchant_location_online_settings_info
            ?.is_online_store_setting_pending
      );

      console.log("allLocationSettingPending", allLocationSettingPending);
      return {
        ...state,
        is_online_store_setting_pending: allLocationSettingPending,
      };
    case "PROFILE_UPDATE":
      return {
        ...state,
        userData: action.payload.userData,
      };
    case "ADD_NEW_LOCATION":
      let new_location = action.payload;
      const modifiedLocation = [
        ...state.userData.merchant_location_info,
        new_location,
      ];
      return {
        ...state,
        userData: {
          ...state.userData,
          merchant_location_info: modifiedLocation,
        },
      };

    case "LOCATION_NAME_UPDATED":
      let { location_id, location_name } = action.payload;
      let location_name_updated_arr =
        state?.userData?.merchant_location_info.map((item, index) => {
          if (item?.location_id === location_id) {
            item.location_name = location_name;
          }
          return item;
        });
      return {
        ...state,
        userData: {
          ...state.userData,
          merchant_location_info: location_name_updated_arr,
        },
      };

    case "WEBSITE_URL_UPDATED":
      // alert("hello")
      return {
        ...state,
        userData: {
          ...state.userData,
          merchant_info: {
            ...state.merchant_info,
            online_store_url: action.payload,
          },
        },
      };

    case "WEBSITE_QR_UPDATED":
      // alert("hello")
      return {
        ...state,
        userData: {
          ...state.userData,
          merchant_info: {
            ...state.merchant_info,
            online_store_qr_code: action.payload,
          },
        },
      };

    case "WEBSITE_URL_AND_QR_UPDATED":
      // alert("hello")
      return {
        ...state,
        userData: {
          ...state.userData,
          merchant_info: {
            ...state.merchant_info,
            online_store_qr_code: action.payload.online_store_qr_code,
            online_store_url: action.payload.online_store_url,
          },
        },
      };
    case "DELETE_MERCHANT_LOCATION":
      let deleted_location_id = action.payload;
      const filterLocation = state.userData?.merchant_location_info.filter(
        (item) => item?.location_id !== deleted_location_id
      );
      state.userData.merchant_location_info = filterLocation;
      return {
        ...state,
      };

    case "USERNAME_UPDATE":
      return {
        ...state,
        userData: { ...state.userData, name: action.payload.name },
      };
    case LOGOUT:
      return {
        userData: {},
        tokenData: {},
        isAuthenticated: false,
        isLoading: false,
      };
    case LOADING:
      return { ...state, userData: {}, isLoading: action.payload };
    default:
      return state;
  }
};

export default authReducer;
