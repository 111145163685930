import {
  LOADING,
  ADD,
  DELETE,
  LIST,
  DETAILS,
  ALL,
  RESET_FILTER,
  SET_FILTER,
} from "../../types/report";
import moment from "moment";
const initialFilter = {
  payment_mode: "",
  // start_date:moment().subtract(1, 'days'),
  start_date: moment().subtract(6, "days").format("YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD"),
  status: "",
  search: {
    type: "",
    value: "",
  },
  sorted: {
    column: "created_at",
    order: "desc",
  },
  page: 1,
  limit: 50,
};

// **  Initial State
const initialState = {
  data: [],
  start: 0,
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  percentage: 0,
  params: {},
  loading: true,
  all: [],
  filter: initialFilter,
};

const transaction = (state = initialState, action) => {
  switch (action.type) {
    case LIST:
      return {
        ...state,
        data: action.payload?.results?.map((item,index)=>{return{...item,id:index+1}}),
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        params: { limit: action.payload.limit, page: action.payload.page },
        transactionStatus: action.payload.transaction_stats,
        loading: false,
      };
    case "GET_TRANSACTION":
      return {
        ...state,
        all: action.payload.results,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    }
    case RESET_FILTER: {
      return {
        ...state,
        filter: {
          ...initialFilter,
        },
      };
    }
    default:
      return state;
  }
};

export default transaction;
