import { LOADING, ADD, DELETE, LIST, DETAILS, ALL } from '../../types/supplier'
// **  Initial State
const initialState = {
  data: [],
  start: 0,
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  percentage: 0,
  params: {},
  loading: true,
  all: [],
}

const manageSupplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST:
      return {
        ...state,
        // data: action.payload.results,
        data: action.payload?.results?.map((item,index)=>{return{...item, id:index+1}}),
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        params: { limit: action.payload.limit, page: action.payload.page },
        loading: false,
      }
    case ALL:
      return {
        ...state,
        all: action.payload.results,
      }
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}

export default manageSupplierReducer
