import { LOADING } from '../../types/myTeam'
// **  Initial State
const initialState = {
  data: [],
  start: 0,
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  percentage: 0,
  params: {},
  loading: true,
  all: [],
  viewPermission: {},
  viewLoader: true,
}

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ROLE':
      return {
        ...state,
        data: action.payload.results,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        params: { limit: action.payload.limit, page: action.payload.page },
        loading: false,
      }
    case 'GET_ALL_ROLE':
      return {
        ...state,
        all: action.payload.results,
      }
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case 'GET_ALL_ROLE_PERMISSION':
      return {
        ...state,
        viewPermission: action.payload.results,
        viewLoader: false,
      }
    case 'VIEW_PERMISSION_LOADER':
      return {
        ...state,
        viewLoader: action.payload,
      }
    case 'GET_MY_TEAM':
      return {
        ...state,
        data: action.payload.results,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        params: { limit: action.payload.limit, page: action.payload.page },
        loading: false,
      }
    default:
      return state
  }
}

export default productReducer
