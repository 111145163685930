// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/";
  if (userRole === "client") return "/access-control";
  return "/login";
};

export const filterPlusMinusEKeys = (e, roundDigits) => {
  if (
    (e.which !== 8 && e.which !== 46 && e.which !== 0 && e.which < 48) ||
    e.which > 57
  ) {
    e.preventDefault();
  }

  if (roundDigits !== undefined && roundDigits >= 0) {
    let number = Number(e.target.value);

    if (roundDigits > 0) {
      if (number && number > 0) {
        const numStr = e.target.value;
        let noOfDecimals = 0;
        // String Contains Decimal
        if (numStr.includes(".")) {
          noOfDecimals = numStr.split(".")[1].length;
        }
        if (noOfDecimals >= roundDigits) {
          e.preventDefault();
        }
        // if (Math.round(number) === number) {
        //   e.target.value = Math.round(number);
        // }
        // number = number.toFixed(roundDigits);
        // e.target.value = Number(number);
      }
    } else if (roundDigits === 0) {
      if (e.which === 46) {
        e.preventDefault();
      }
    }
  }
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

const f = (a, b) => [].concat(...a.map((d) => b.map((e) => [].concat(d, e))));
export const cartesian = (a, b, ...c) =>
  a && a.length && b && b.length > 0 ? cartesian(f(a, b), ...c) : a || [];
// https://stackoverflow.com/questions/12303989/cartesian-product-of-multiple-arrays-in-javascript/43053803#43053803

//Password Validation
export const validateYupSchema = async (yupSchema, data) => {
  try {
    const result = await yupSchema.validate(data, { abortEarly: false });
    console.log("-> result", result);
    return {};
  } catch (err) {
    console.log("-> err", err);
    const errorsObj = {};
    err.inner.forEach((err) => {
      const { errors, path } = err;
      errorsObj[path] = formatErrors(path, errors);
    });
    return errorsObj;
  }
};
const formatErrors = (fieldName, errors) => {
  const errorsArray = errors;
  if (errorsArray && errorsArray.length > 0) {
    let errorString = "";
    errorsArray.forEach((err) => {
      errorString = errorString + " " + err;
    });
    return errorString;
  }
  return "";
};

export const formatNumber = (num) => {
  if (isNaN(num)) {
    return "0.00";
  }
  return Number(num).toFixed(2);
};

export function format_time_discount(time) {
  // formats a javascript Date object into a 12h AM/PM time string
  var hour = parseInt(time.split(":")[0]);
  var minute = parseInt(time.split(":")[1]);
  var amPM = hour > 11 ? " pm" : " am";
  if (hour > 12) {
    hour -= 12;
  } else if (hour == 0) {
    hour = "12";
  }
  if (minute < 10) {
    minute = "0" + minute;
  }
  return hour + ":" + minute + amPM;
}
export const compareTwoArrayOfObjects = (
  first_array_of_objects,
  second_array_of_objects
) => {
  return second_array_of_objects.filter((element_1) =>
    first_array_of_objects.some((element_2) => element_1._id === element_2._id)
  );
};

// export const compareDates = (startDate, endDate, isStartDate) => {
//   const date1 = new Date(startDate);
//   2023;
//   const date2 = new Date(endDate);
//   2000;
//   if (isStartDate) {
//     if (date1 < date2) {
//       return true;
//     } else if (date1 > date2) {
//       return false;
//     } else {
//       return true;
//     }
//   } else {
//     if (date1 > date2) {
//       return false;
//     } else {
//       return true;
//     }
//   }
// };
