// **  Initial State
const initialState = {
    limit: process.env.REACT_APP_PAGE_LIMIT,
    page: 1,
    totalPages: 1,
    rowsPerPage:process.env.REACT_APP_PAGE_LIMIT
  }

const discountAndPromotion = (state = initialState, action) => {
  switch (action.type) {
    case 'META_TAG_DISCOUNT':
      return {
        ...state,
        totalPages: action.payload.data?.totalPages,
        rowsPerPage:action.payload.data?.limit,
        limit:action.payload?.data?.limit,
        page:action.payload?.data?.page
      }
    default:
      return state
  }
}

export default discountAndPromotion
