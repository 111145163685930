
// **  Initial State
const initialState = {
    data: [],
  }
  
  const staffAttenTimeSheetReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'STAFF_ATTEN_TIMESHEET':
        return {
          ...state,
          data: action.payload,
        }
      default:
        return state
    }
  }
  
  export default staffAttenTimeSheetReducer
  