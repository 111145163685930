// **  Initial State
const initialFilterObj = {
  search: "",
  page: 1,
  limit: process.env.REACT_APP_PAGE_LIMIT,
  sorted: {
    value: { column: "available", order: "asc" },
    label: "Available (Ascending)",
  },
};

const initialState = {
  data: [],
  start: 0,
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  percentage: 0,
  currentPage: 1,
  params: {},
  loading: true,
  filter: initialFilterObj,
};

const unitReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
          page: action.payload.page ? action.payload.page : 1,
        },
      };
    case "RESET_FILTER":
      return {
        ...state,
        filter: { ...initialFilterObj },
      };
    default:
      return state;
  }
};

export default unitReducer;
