import { ALL, LIST, LOADING, SET_FILTER } from "../../types/transfer";
// **  Initial State

const initialFilterObj = {
  search: "",
  page: 1,
  limit: process.env.REACT_APP_PAGE_LIMIT,
  //sorted: { value: {"column": "available","order": "asc"}, label: 'Available (Ascending)' },
};

const initialState = {
  data: [],
  start: 0,
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  percentage: 0,
  params: {},
  loading: true,
  all: [],
  filter: initialFilterObj,
};

const transferReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST:
      return {
        ...state,
        data: action.payload.results,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        params: { limit: action.payload.limit, page: action.payload.page },
        loading: false,
      };
    case ALL:
      return {
        ...state,
        all: action.payload.results,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...action.payload },
      };
    default:
      return state;
  }
};

export default transferReducer;
