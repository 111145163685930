// **  Initial State
const initialState = {
  data: [],
  start: 0,
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  percentage: 0,
  params: {},
  loading: true,
  currentPage:0,
  sorted: {
    column: "name",
    order: "asc",
  },
  is_access_denied: false
};

const findProductAndUpdate = (data, item) => {
  var foundIndex = data.findIndex((x) => x._id == item._id);
  const oldItem = data[foundIndex];
  data[foundIndex] = { ...oldItem, ...item };
  return [...data];
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PRODUCT":
      console.log('action.payload',action.payload)
      return {
        ...state,
        data: action.payload.results,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        params: { limit: action.payload.limit, page: action.payload.page },
        loading: false,
        currentPage:action.payload.page,
        sorted:action.payload.sorted
      };
    case "PRODUCT_LOADING":
      return {
        ...state,
        loading: action.payload,
      };

    case "CHANGE_PRODUCT_STATUS":
      return {
        ...state,
        data: findProductAndUpdate(state.data, action.payload),
      };
      case 'ACCESS_DENIED':
      return {
        ...state,
        is_access_denied:action.payload 
      }
    default:
      return state;
  }
};

export default productReducer;
