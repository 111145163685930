import { LIST, LOADING } from "../../types/customers";
// **  Initial State
const initialState = {
  data: [],
  start: 0,
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  percentage: 0,
  params: {},
  loading: true,
  all: [],
};
const findCustomersAndUpdate = (data, item) => {
  var foundIndex = data.findIndex((x) => x._id == item._id);
  const oldItem = data[foundIndex];
  data[foundIndex] = { ...oldItem, ...item };
  return [...data];
};

const customers = (state = initialState, action) => {
  switch (action.type) {
    case LIST:
      console.log("action.payload123", action.payload);
      return {
        ...state,
        // data: action.payload.results.map((item,index)=>{
        //   return{...item,id:index+1}
        // }),
        data: action.payload.results?.map((item, index) => {
          return { ...item, id: index + 1 };
        }),
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        params: { limit: action.payload.limit, page: action.payload.page },
        loading: false,
      };
    case "GET_CUSTOMERS":
      return {
        ...state,
        all: action.payload.results,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case "CHANGE_CUSTOMER_STATUS":
      return {
        ...state,
        data: findCustomersAndUpdate(state.data, action.payload),
      };
    default:
      return state;
  }
};

export default customers;
