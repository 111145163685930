// **  Initial State
const initialFilterObj = {
  search: "",
  page: 1,
  limit: process.env.REACT_APP_PAGE_LIMIT,
  sorted: {
    value: { column: "available", order: "asc" },
    label: "Available (Ascending)",
  },
};

const initialState = {
  data: [],
  start: 0,
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  percentage: 0,
  currentPage: 1,
  params: {},
  loading: true,
  filter: initialFilterObj,
};

const recipeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_RECIPE_SUCCESS":
      return {
        ...state,
        data: action.payload.results,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        currentPage: action.payload.page,
        params: { limit: action.payload.limit, page: action.payload.page },
        loading: false,
      };
    case "EDIT_INVENTORY_ITEM_SUCCESS":
      const updatedData = state.data.map((obj) => {
        if (obj._id === action.payload.item._id) {
          return { ...obj, ...action?.payload?.item };
        }
        return obj;
      });

      return {
        ...state,
        data: updatedData,
      };
    case "INVENTORY_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_FILTER":
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
          page: action.payload.page ? action.payload.page : 1,
        },
      };
    case "RESET_FILTER":
      return {
        ...state,
        filter: { ...initialFilterObj },
      };
    default:
      return state;
  }
};

export default recipeReducer;
