// **  Initial State
const initialState = {
  data: [],
  start: 0,
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  percentage: 0,
  params: {},
  all: [],
  loading: true,
}

const merchantProductAttributes = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MERCHANT_PRODUCT_ATTRIBUTES':
      return {
        ...state,
        data: action.payload.results,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        params: { limit: action.payload.limit, page: action.payload.page },
        loading: false,
      }
    case 'GET_ALL_MERCHANT_PRODUCT_ATTRIBUTES':
      return {
        ...state,
        all: action.payload.results,
      }
    case 'MERCHANT_PRODUCT_ATTRIBUTES_LOADING':
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}

export default merchantProductAttributes
