// **  Initial State
const initialState = {
  data: [],
  start: 0,
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  percentage: 0,
  params: {},
  loading: true,
  all: [],
};

const iPadGridLayoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_GRID_LAYOUT":
      return {
        ...state,
        data: [...action.payload.data.results],
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        params: { limit: action.payload.limit, page: action.payload.page },
        loading: false,
      };
    default:
      return state;
  }
};

export default iPadGridLayoutReducer;
