// ** Initial State
const initialState = {
  locale: "in",
  messages: "",
  direction: "",
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_LOCALE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default layoutReducer;
