import {
  ADD_PRODUCT_SETUP,
  DELETE_PRODUCT_SETUP,
  ADD_STAFF_SETUP,
  ADD_DEVICE_SETUP,
  DELETE_STAFF_SETUP,
  DELETE_DEVICE_SETUP,
  SETUP_GUIDE_DATA,
} from "../../types/setupGuide";

const setupGuideReducer = (
  state = { setupStep: 0, setupGuideData: {} },
  action
) => {
  switch (action.type) {
    case SETUP_GUIDE_DATA:
      let isSetupGuideProductActive = 0;
      // console.log("setupGuideReducer get called", action.payload);
      for (const key in action.payload) {
        if (Object.hasOwnProperty.call(action.payload, key)) {
          const element = action.payload[key];
          if (element) {
            isSetupGuideProductActive += 1;
          }
        }
      }
      return {
        ...state,
        setupStep: isSetupGuideProductActive,
        setupGuideData: { ...action.payload },
      };
    // case ADD_DEVICE_SETUP:
    //   console.log("ADD_DEVICE_SETUP Get Called", action.payload);
    //   for (const key in action.payload) {
    //     if (Object.hasOwnProperty.call(action.payload, key)) {
    //       const element = action.payload[key];
    //       if (element) {
    //         isSetupGuideProductActive += 1;
    //       }
    //     }
    //   }

    //   return {
    //     ...state,
    //     setupStep: isSetupGuideProductActive,
    //     setupGuideData: { ...state },
    //   };
    default:
      return state;
  }
};

export default setupGuideReducer;
