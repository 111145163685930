import { LOADING, LIST, DETAILS, ALL } from "../../types/report";

// **  Initial State
const initialState = {
  data: [],
  start: 0,
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  percentage: 0,
  params: {},
  loading: false,
  all: [],
};

const paymentMethod = (state = initialState, action) => {
  switch (action.type) {
    case LIST:
      return {
        ...state,
        data: action.payload.results,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        totalPaymentMethodStats: action.payload.total_payment_method_stats,
        params: { limit: action.payload.limit, page: action.payload.page },
        loading: false,
      };
    case "GET_PAYMENT_METHOD":
      return {
        ...state,
        all: action.payload.results,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default paymentMethod;
