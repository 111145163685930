const purchaseNewSubscriptionReducer = (
  state = { new_subscription: null },
  action
) => {
  // console.log("PURCHASE_NEW_SUBSCRIPTION get called", action.payload);
  switch (action.type) {
    case "PURCHASE_NEW_SUBSCRIPTION":
      return {
        ...state,
        new_subscription: action.payload,
      };
    default:
      return state;
  }
};

export default purchaseNewSubscriptionReducer;
