const intialFilter = {
  dstatus: [],
  search: {
    value: "",
  },
  sorted: {
    column: "created_at",
    order: "desc",
  },
  page: 1,
  limit: 30,
};

const initialState = {
  data: [],
  start: 0,
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  currentPage: 0,
  percentage: 0,
  loading: true,
  all: [],
  delivery_status_stats: [],
  viewPermission: {},
  viewLoader: true,
  filter: intialFilter,

};

const onlineOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    
    // case "GET_ONLINE_ORDER_SET_LOCATION":
    //   return {
    //     ...state,
    //     all: [...state.all, ...action.payload.online_orders],
    //     data: action.payload.online_orders,
    //     delivery_status_stats: action.payload?.delivery_status_stats,
    //     totalPages: action.payload.totalPages,
    //     totalRecords: action.payload.totalResults,
    //     currentPage: action.payload.page,
    //     filter: { ...state.filter, page: 1 },
    //     loading: false,
    //   };
    case "GET_ONLINE_ORDER":
      return {
        ...state,
        all: [...state.all, ...action.payload.online_orders],
        data: action.payload.online_orders,
        delivery_status_stats: action.payload?.delivery_status_stats,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        currentPage: action.payload.page,
        filter: { ...state.filter, page: action.payload.page + 1 },
        loading: false,
      };
    case "ORDER_SET_FILTER":
      return {
        ...state,
        currentPage: 1,
        filter: { ...state.filter, ...action.payload },
        all: action?.payload?.reset ? [] : state.all,
        loading: action?.payload?.reset ? true : false,
      };
    case "ORDER_RESET_FILTER":
      return {
        ...state,
        all: [],
        currentPage: 1,
        filter: { ...intialFilter },
        loading: true,
      };
    case "UPDATE_ORDER_STATUS":
      let newItems = state.all.map((item) => {
        if (item._id !== action.payload._id) {
          // This isn't the item we care about - keep it as-is
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...action.payload,
        };
      });
      newItems = newItems.filter(
        (item) => item?.order_delivery_status !== "DELIVERED"
      );
      const new_delivery_status_stats = state.delivery_status_stats.map(
        (item) => {
          if (item.delivery_status === action.payload?.currentDeliveryStatus) {
            item.total_orders = item.total_orders - 1;
          } else if (
            item.delivery_status === action.payload?.order_delivery_status
          ) {
            item.total_orders = item.total_orders + 1;
          } else if (
            action.payload?.order_delivery_status === "DELIVERED" &&
            item?.delivery_status === "ALL"
          ) {
            item.total_orders = item.total_orders - 1;
          }
          return item;
        }
      );
      return {
        ...state,
        delivery_status_stats: [...new_delivery_status_stats],
        all: [...newItems],
      };
    default:
      return state;
  }
};

export const selectOnlineOrderList = (state) => state.onlineOrderReducer.data;
export const selectAllOnlineOrderList = (state) => state.onlineOrderReducer.all;

export default onlineOrderReducer;
