import {
  LOADING,
  ADD,
  DELETE,
  LIST,
  DETAILS,
  ALL,
  LOGOUT,
} from "../../types/manageDevice";
// **  Initial State
const initialState = {
  data: [],
  start: 0,
  posData: [],
  waiterData: [],
  end: 0,
  totalRecords: 0,
  totalPages: 0,
  percentage: 0,
  params: {},
  loading: true,
  all: [],
};

const manageDeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST:
      return {
        ...state,
        data: action.payload.results,
        posData: action.payload.results.find((item) => item._id === "POS")
          ?.data,
        waiterData: action.payload.results.find((item) => item._id === "WAITER")
          ?.data,
        kdsData: action.payload.results.find((item) => item._id === "KDS")
          ?.data,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalResults,
        params: { limit: action.payload.limit, page: action.payload.page },
        loading: false,
      };
    case ALL:
      return {
        ...state,
        all: action.payload.results,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default manageDeviceReducer;
