// ** Redux Imports
import { combineReducers } from "redux";
// ** Reducers Imports
import auth from "./auth";
import inventory from "./inventory";
import category from "./category";
import layout from "./layout";
import localization from "./localization";
import merchantProductAttributes from "./merchantProductAttributes";
import modifier from "./modifier";
import myTeam from "./myTeam";
import role from "./role";
import navbar from "./navbar";
import printerStation from "./printerStation";
import product from "./product";
import tax from "./manageTax";
import location from "./location";
import productAttribute from "./productAttribute";
import customers from "./customers";
import manageDevice from "./manageDevice";
import supplier from "./supplier";
import charges from "./extraCharge";
import receipt from "./receipt";
import collections from "./collections";
import brand from "./brand";
import transfer from "./transfer";
import transaction from "./transactionReport";
import paymentMethod from "./paymentMethod";
import dashboard from "./dashboard";
import salesSummary from "./salesSummary";
import iPadGridLayout from "./ipadGridLayout";
import onlineOrderReducer from "./onlineOrder";
import onlineStoreReducer from "./onlineStore";
import batchProcessReducer from "./batchProcess";
import purchaseNewSubscriptionReducer from "./purchase_new_subscription";
import setupGuideReducer from "./setupGuide";
import cashDrawerActivityReport from "./cashDrawerActivityReport";
import staffAttenTimeSheet from "./staffAttenTimeSheet";
import discountAndPromotion from "./discountAndPromation/index";
import bankReducer from "./bankAccount";
import unitReducer from "./unit";
import rowMaterialReducer from "./rowMaterial";
import inventoryDashnoard from "./inventoryDashnoard";
import recipeReducer from "./recipe";
import inventoryCategoryReducer from "./inventoryCategory";
const combinedReducer = combineReducers({
  auth,
  brand,
  navbar,
  layout,
  localization,
  category,
  modifier,
  printerStation,
  tax,
  product,
  merchantProductAttributes,
  myTeam,
  location,
  role,
  productAttribute,
  customers,
  manageDevice,
  supplier,
  charges,
  receipt,
  collections,
  inventory,
  transfer,
  transaction,
  paymentMethod,
  dashboard,
  salesSummary,
  iPadGridLayout,
  onlineOrderReducer,
  onlineStoreReducer,
  batchProcessReducer,
  purchaseNewSubscriptionReducer,
  setupGuideReducer,
  cashDrawerActivityReport,
  staffAttenTimeSheet,
  discountAndPromotion,
  bankReducer,
  unitReducer,
  rowMaterialReducer,
  inventoryDashnoard,
  recipeReducer,
  inventoryCategoryReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
